import { AwsRum, AwsRumConfig, TelemetryEnum } from 'aws-rum-web';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useUserAlias } from 'src/common/hooks/useUserAlias';
import { Page } from 'src/common/types/Page';
import { RumEvents } from 'src/common/types/RumEvents';

const ALLOW_COOKIES = true;
const APPLICATION_ID = '71d69f6a-3e9b-4c4d-9166-b241a2e81bd6';
const APPLICATION_VERSION = '1.0,0';
const DISABLE_AUTO_PAGE_VIEW = false;
const ENABLE_XRAY = true;
const GUEST_ROLE_ARN = 'arn:aws:iam::524602346818:role/FoxDenMECFrontendWebsiteCognitoUnauthenticatedRole-beta';
const IDENTITY_POOL_ID = 'us-west-2:d5830d30-689a-474c-b8e8-e52918c4a4a7';
const REGION = 'us-west-2';
const RUM_ENDPOINT = 'https://dataplane.rum.us-west-2.amazonaws.com';
const SESSION_SAMPLE_RATE = 1;

interface AppRumContextProps {
  recordError: (error: any) => void;
  recordEvent: (
    eventType: RumEvents,
    eventData: Record<string, unknown>,
  ) => void;
  recordPageView: (pageId: Page) => void;
  addSessionAttributes: (
    sessionAttributes: Record<string, boolean | string | number>,
  ) => void;
}

const AppRumContext = createContext<AppRumContextProps>({
  recordError: () => {},
  recordEvent: () => {},
  recordPageView: () => {},
  addSessionAttributes: () => {},
});

type AwsRumProviderProps = AwsRumConfig & {
  children: React.ReactNode;
};

export const AwsRumProvider = ({ children }: AwsRumProviderProps) => {
  const { alias } = useUserAlias();

  const awsRum = useMemo(
    () =>
      new AwsRum(APPLICATION_ID, APPLICATION_VERSION, REGION, {
        allowCookies: ALLOW_COOKIES,
        cookieAttributes: {
          domain: window.location.hostname,
          path: '/',
          sameSite: 'Lax',
          secure: true,
          unique: true,
        },
        sessionAttributes: {
          user: alias,
        },
        disableAutoPageView: DISABLE_AUTO_PAGE_VIEW,
        enableXRay: ENABLE_XRAY,
        endpoint: RUM_ENDPOINT,
        guestRoleArn: GUEST_ROLE_ARN,
        identityPoolId: IDENTITY_POOL_ID,
        sessionSampleRate: SESSION_SAMPLE_RATE,
        telemetries: [
          TelemetryEnum.Errors,
          TelemetryEnum.Http,
          TelemetryEnum.Interaction,
          TelemetryEnum.Performance,
        ],
      }),
    [alias],
  );

  /**
   * Enable AWS RUM client to start recording and dispatching RUM events.
   */
  useEffect(() => {
    awsRum.enable();
  }, [awsRum]);

  /**
   * Add session attributes to the AWS RUM client
   * @param sessionAttributes - The session attributes to add
   */
  const handleAddSessionAttributes = useCallback(
    (sessionAttributes: Record<string, boolean | string | number>) => {
      awsRum.addSessionAttributes(sessionAttributes);
    },
    [awsRum],
  );

  /**
   * Record event using the AWS RUM client
   * @param eventType - The event type to record
   * @param eventData - The event data to record
   */
  const handleRecordEvent = useCallback(
    (eventType: RumEvents, eventData: Record<string, unknown>) => {
      awsRum.recordEvent(eventType, eventData);
      awsRum.dispatch();
    },
    [awsRum],
  );

  /**
   * Record page view using the AWS RUM client
   * @param pageId - The page id to record
   */
  const handleRecordPageView = useCallback(
    (pageId: Page, pageTags?: string[]) => {
      awsRum.recordPageView({ pageId, pageTags });
      awsRum.dispatch();
    },
    [awsRum],
  );

  /**
   * Record error using the AWS RUM client
   * @param error - The error to record
   */
  const handleRecordError = useCallback(
    (error: any) => {
      awsRum.recordError(error);
      awsRum.dispatch();
    },
    [awsRum],
  );

  return (
    <AppRumContext.Provider
      value={{
        recordError: handleRecordError,
        recordEvent: handleRecordEvent,
        recordPageView: handleRecordPageView,
        addSessionAttributes: handleAddSessionAttributes,
      }}
    >
      {children}
    </AppRumContext.Provider>
  );
};

export const useAwsRum = () => useContext(AppRumContext);
